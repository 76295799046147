export const calculateItemTotal = item => {
    let total = 0;

    if (!item && !fees) {
        return 0;
    }
    total += item.price * item.quantity;
    if (Number.isNaN(total)) {
        return 0;
    }
    return total;
};

export const calculateOrderTotal = (items, fees) => {
    let total = 0;

    if (!items && !fees) {
        return 0;
    }
    items.forEach(item => {
        total += item.price * item.quantity;
    });
    fees.forEach(fee => {
        total += Number(fee.total);
    });

    if (Number.isNaN(total)) {
        return 0;
    }

    return total;
};
