export const resolveItemNames = name => {
    let names = {
        first_name: "First Name",
        last_name: "Last Name",
        address_1: "Address Line 1",
        address_2: "Address Line 2",
        city: "City",
        state: "State",
        postcode: "Postcode",
        country: "Country",
        email: "Email",
        phone: "Phone",
        company: "Company",
    };

    return names[name] || name;
};
